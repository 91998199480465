export const CREATE_ORDER_REQUEST = "CREATE_ORDER_REQUEST";
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS";
export const CREATE_ORDER_FAILURE = "CREATE_ORDER_FAILURE";
export const CREATE_ORDER_RESET = "CREATE_ORDER_RESET";

export const ORDER_DETAILS_REQUEST = "ORDER_DETAILS_REQUEST";
export const ORDER_DETAILS_SUCCESS = "ORDER_DETAILS_SUCCESS";
export const ORDER_DETAILS_FAILURE = "ORDER_DETAILS_FAILURE";

export const ORDER_PAY_REQUEST = "ORDER_PAY_REQUEST";
export const ORDER_PAY_SUCCESS = "ORDER_PAY_SUCCESS";
export const ORDER_PAY_FAILURE = "ORDER_PAY_FAILURE";
export const ORDER_PAY_RESET = "ORDER_PAY_RESET";

export const ORDER_LIST_REQUEST = "ORDER_LIST_REQUEST";
export const ORDER_LIST_SUCCESS = "ORDER_LIST_SUCCESS";
export const ORDER_LIST_FAILURE = "ORDER_LIST_FAILURE";

export const ORDER_DELETE_REQUEST = "ORDER_DELETE_REQUEST";
export const ORDER_DELETE_SUCCESS = "ORDER_DELETE_SUCCESS";
export const ORDER_DELETE_FAILURE = "ORDER_DELETE_FAILURE";
export const ORDER_DELETE_RESET = "ORDER_DELETE_RESET";

//ADMIN
export const ADMIN_ORDER_LIST_REQUEST = "ADMIN_ORDER_LIST_REQUEST";
export const ADMIN_ORDER_LIST_SUCCESS = "ADMIN_ORDER_LIST_SUCCESS";
export const ADMIN_ORDER_LIST_FAILURE = "ADMIN_ORDER_LIST_FAILURE";

export const ADMIN_ORDER_DETAILS_REQUEST = "ADMIN_ORDER_DETAILS_REQUEST";
export const ADMIN_ORDER_DETAILS_SUCCESS = "ADMIN_ORDER_DETAILS_SUCCESS";
export const ADMIN_ORDER_DETAILS_FAILURE = "ADMIN_ORDER_DETAILS_FAILURE";

export const ADMIN_ORDER_DELIVERED_REQUEST = "ADMIN_ORDER_DELIVERED_REQUEST";
export const ADMIN_ORDER_DELIVERED_SUCCESS = "ADMIN_ORDER_DELIVERED_SUCCESS";
export const ADMIN_ORDER_DELIVERED_FAILURE = "ADMIN_ORDER_DELIVERED_FAILURE";
export const ADMIN_ORDER_DELIVERED_RESET = "ADMIN_ORDER_DELIVERED_RESET";
