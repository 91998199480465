import React from 'react'
import AddMainProduct from '../components/products/AddMainProduct';

function AddProduct() {
  return (
    <>
      <main className="main-wrap">
        <AddMainProduct />
      </main>
    </>
  );
}

export default AddProduct