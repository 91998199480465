import React from 'react'
import  OrderMain from "../components/orders/OrderMain";

function OrderScreen() {
  return (
    <>
      <main className="main-wrap">
        <OrderMain />
      </main>
    </>
  );
}

export default OrderScreen