import React from "react";
import Main from "../components/dashboard/Main";

function HomeScreen() {

  return (
    <>
      <main className="main-wrap">
        <Main />
      </main>
    </>
  );
}

export default HomeScreen;
