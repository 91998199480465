import React from "react";
import MainProducts from "../components/products/MainProducts";

function Products() {
  return (
    <>
      <main className="main-wrap">
        <MainProducts />
      </main>
    </>
  );
}

export default Products;
