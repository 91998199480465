//PRODUCT LIST
export const PRODUCT_LIST_REQUEST = "PRODUCT_LIST_REQUEST";
export const PRODUCT_LIST_SUCCESS = "PRODUCT_LIST_SUCCESS";
export const PRODUCT_LIST_FAILURE = "PRODUCT_LIST_FAILURE";

//SINGLE PRODUCT
export const PRODUCT_DETAIL_REQUEST = "PRODUCT_DETAIL_REQUEST";
export const PRODUCT_DETAIL_SUCCESS = "PRODUCT_DETAIL_SUCCESS";
export const PRODUCT_DETAIL_FAILURE = "PRODUCT_DETAIL_FAILURE";


//PRODUCT REVIEW
export const PRODUCT_CREATE_REVIEW_REQUEST = "PRODUCT_CREATE_REVIEW_REQUEST";
export const PRODUCT_CREATE_REVIEW_SUCCESS = "PRODUCT_CREATE_REVIEW_SUCCESS";
export const PRODUCT_CREATE_REVIEW_FAILURE = "PRODUCT_CREATE_REVIEW_FAILURE";
export const PRODUCT_CREATE_REVIEW_RESET = "PRODUCT_CREATE_REVIEW_RESET";

// ADMIN TYPES
export const ADMIN_PRODUCT_LIST_REQUEST = "ADMIN_PRODUCT_LIST_REQUEST";
export const ADMIN_PRODUCT_LIST_SUCCESS = "ADMIN_PRODUCT_LIST_SUCCESS";
export const ADMIN_PRODUCT_LIST_FAILURE = "ADMIN_PRODUCT_LIST_FAILURE";

export const ADMIN_PRODUCT_DELETE_REQUEST = "ADMIN_PRODUCT_DELETE_REQUEST";
export const ADMIN_PRODUCT_DELETE_SUCCESS = "ADMIN_PRODUCT_DELETE_SUCCESS";
export const ADMIN_PRODUCT_DELETE_FAILURE = "ADMIN_PRODUCT_DELETE_FAILURE";
export const ADMIN_PRODUCT_DELETE_RESET = "ADMIN_PRODUCT_DELETE_RESET";

export const ADMIN_PRODUCT_CREATE_REQUEST = "ADMIN_PRODUCT_CREATE_REQUEST";
export const ADMIN_PRODUCT_CREATE_SUCCESS = "ADMIN_PRODUCT_CREATE_SUCCESS";
export const ADMIN_PRODUCT_CREATE_FAILURE = "ADMIN_PRODUCT_CREATE_FAILURE";
export const ADMIN_PRODUCT_CREATE_RESET = "ADMIN_PRODUCT_CREATE_RESET";

export const ADMIN_PRODUCT_EDIT_REQUEST = "ADMIN_PRODUCT_EDIT_REQUEST";
export const ADMIN_PRODUCT_EDIT_SUCCESS = "ADMIN_PRODUCT_EDIT_SUCCESS";
export const ADMIN_PRODUCT_EDIT_FAILURE = "ADMIN_PRODUCT_EDIT_FAILURE";

export const ADMIN_PRODUCT_UPDATE_REQUEST = "ADMIN_PRODUCT_UPDATE_REQUEST";
export const ADMIN_PRODUCT_UPDATE_SUCCESS = "ADMIN_PRODUCT_UPDATE_SUCCESS";
export const ADMIN_PRODUCT_UPDATE_FAILURE = "ADMIN_PRODUCT_UPDATE_FAILURE";
export const ADMIN_PRODUCT_UPDATE_RESET = "ADMIN_PRODUCT_UPDATE_RESET";

export const ADMIN_PRODUCT_DETAIL_REQUEST = "ADMIN_PRODUCT_DETAIL_REQUEST";
export const ADMIN_PRODUCT_DETAIL_SUCCESS = "ADMIN_PRODUCT_DETAIL_SUCCESS";
export const ADMIN_PRODUCT_DETAIL_FAILURE = "ADMIN_PRODUCT_DETAIL_FAILURE";