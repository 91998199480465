import React from "react";
import Users from "../components/Users";

function UserScreen() {

  return (
    <>
      <main className="main-wrap">
        <Users />
      </main>
    </>
  );
}

export default UserScreen;
