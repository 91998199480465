export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAILURE = "USER_REGISTER_FAILURE";

export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";
export const USER_LOGOUT = "USER_LOGOUT";

export const USER_PROFILE_REQUEST = "USER_PROFILE_REQUEST";
export const USER_PROFILE_SUCCESS = "USER_PROFILE_SUCCESS";
export const USER_PROFILE_FAILURE = "USER_PROFILE_FAILURE";

export const USER_PROFILE_UPDATE_REQUEST = "USER_PROFILE_UPDATE_REQUEST";
export const USER_PROFILE_UPDATE_SUCCESS = "USER_PROFILE_UPDATE_SUCCESS";
export const USER_PROFILE_UPDATE_FAILURE = "USER_PROFILE_UPDATE_FAILURE";
export const USER_PROFILE_UPDATE_RESET = "USER_PROFILE_UPDATE_RESET";

export const ADMIN_USERS_LIST_REQUEST = "ADMIN_USERS_LIST_REQUEST";
export const ADMIN_USERS_LIST_SUCCESS = "ADMIN_USERS_LIST_SUCCESS";
export const ADMIN_USERS_LIST_FAILURE = "ADMIN_USERS_LIST_FAILURE";
export const ADMIN_USERS_LIST_RESET = "ADMIN_USERS_LIST_RESET";